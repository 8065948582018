import http from "./http";
export default (packingId) => ({
  async index(filters) {
    return http
      .index("packings", filters)
      .then((json) => ({ packings: json }))
      .catch(async (e) => ({ packings: null, error: await e }));
  },
  async get() {
    return http
      .get("packings", packingId)
      .then((json) => ({ packing: json }))
      .catch(async (e) => ({ packing: null, error: await e }));
  },
  async create(data) {
    return http
      .post("packings", data)
      .then((json) => ({ packing: json }))
      .catch(async (e) => ({ packing: null, error: await e }));
  },
  async update(data) {
    return http
      .put("packings", packingId, data)
      .then((json) => ({ packing: json }))
      .catch(async (e) => ({ packing: null, error: await e }));
  },
  async delete() {
    return http
      .delete("packings", packingId)
      .then((json) => ({ packing: json }))
      .catch(async (e) => ({ packing: null, error: await e }));
  },
});

import http from "./http";
export default (categoryId) => ({
  async index(filters) {
    return http
      .index("categories", filters)
      .then((json) => ({ categories: json }))
      .catch(async (e) => ({ categories: null, error: await e }));
  },
  async get() {
    return http
      .get("categories", categoryId)
      .then((json) => ({ category: json }))
      .catch(async (e) => ({ category: null, error: await e }));
  },
  async create(data) {
    return http
      .post("categories", data)
      .then((json) => ({ category: json }))
      .catch(async (e) => ({ category: null, error: await e }));
  },
  async update(data) {
    return http
      .put("categories", categoryId, data)
      .then((json) => ({ category: json }))
      .catch(async (e) => ({ category: null, error: await e }));
  },
  async delete() {
    return http
      .delete("categories", categoryId)
      .then((json) => ({ category: json }))
      .catch(async (e) => ({ category: null, error: await e }));
  },
});

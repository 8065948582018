<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :xs="24">
          <el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Mês de referência: </template>
        <el-date-picker v-model="dateReference" type="month" size="medium">
        </el-date-picker>
      </el-descriptions-item>
    </el-descriptions>
    <el-descriptions size="large" border>
      <el-descriptions-item>
        <template #label> Nota fiscal: </template>
        <el-row type="flex" justify="end">
          <el-radio-group
            v-model="searchWithInvoiceMode"
            size="large"
            @change="fetchOrders"
          >
            <el-radio-button label="Apenas com nota" />
            <el-radio-button label="Apenas sem nota" />
            <el-radio-button label="Indiferente" />
          </el-radio-group>
        </el-row>
      </el-descriptions-item>
    </el-descriptions>
    <el-row type="flex" :gutter="8">
      <el-col :md="24" :xs="24">
        <el-table
          :data="Categories"
          stripe
          style="width: 100%"
          :cell-style="() => 'text-align:center;'"
        >
          <el-table-column prop="name" label="categoria" min-width="250">
          </el-table-column>
          <el-table-column label="venda">
            <template #default="{ row: category }">
              <div v-if="'soldQuantity' in category">
                {{ weightFormatter.format(Number(category.soldQuantity)) }} kg.
              </div>
              <div v-else class="center-loading">
                <i class="el-icon-loading" :id="category.uid"></i>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="compra">
            <template #default="{ row: category }">
              <div
                v-if="
                  'boughtQuantityEntries' in category ||
                  'boughtQuantityPackings' in category
                "
              >
                {{
                  weightFormatter.format(
                    (Number(category.boughtQuantityEntries) || 0) +
                      (Number(category.boughtQuantityPackings) || 0)
                  )
                }}
                kg.
              </div>
              <div v-else class="center-loading">
                <i class="el-icon-loading" :id="category.uid"></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <el-col :md="12" :xs="24"> </el-col>
    </el-row>
  </el-card>
</template>

<script>
import OrderService from "../services/orders";
import { weightFormatter } from "../utils/formatters";
import ProductCategoryService from "../services/categories";
import ProductEntriesService from "../services/entries";
import PackingListService from "../services/packings";
export default {
  name: "OrderQuantitiesReport",
  data: () => ({
    isLoading: false,
    weightFormatter,
    dateReference: new Date(),
    categories: null,
    orders: null,
    packings: null,
    searchWithInvoiceMode: "Apenas com nota",
  }),
  computed: {
    Categories() {
      return this.categories || [];
    },
    Orders() {
      return this.orders || [];
    },
    StartOfMonth() {
      const startOfMonth = new Date(this.dateReference);
      startOfMonth.setDate(1);
      startOfMonth.setHours(0, 0, 0, 0);

      return startOfMonth;
    },
    EndOfMonth() {
      const endOfMonth = new Date(this.dateReference);
      endOfMonth.setMonth(endOfMonth.getMonth() + 1);
      endOfMonth.setDate(0);
      endOfMonth.setHours(23, 59, 59, 999);

      return endOfMonth;
    },
  },
  watch: {
    dateReference() {
      this.fetchData();
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      await this.fetchCategories();
      this.fetchOrders();
      this.fetchEntries();
      this.fetchPackings();
    },
    shouldFetchWithInvoices() {
      return this.searchWithInvoiceMode === "Apenas com nota";
    },
    async fetchPackings() {
      const { packings } = await PackingListService().index({
        created_at_start: this.StartOfMonth.toISOString(),
        created_at_end: this.EndOfMonth.toISOString(),
      });

      if (packings) {
        this.packings = packings;
        this.sumBoughtValueForCattleCategory();
      }
    },
    explodeAnimalsInPackings() {
      return this.packings
        ?.map((p) => p.animals)
        .flat()
        .map((a) => a.carcasses)
        .flat();
    },
    explodeProductsInOrders() {
      return this.orders?.map((o) => o.products).flat();
    },
    sumProductsByProductCategoryId(categoryId, products) {
      return products?.reduce(
        (t, p) =>
          t + (p.product.category.uid === categoryId ? Number(p.weight) : 0),
        0
      );
    },
    sumBoughtPackingsWeight() {
      return this.explodeAnimalsInPackings().reduce(
        (total, { weight }) => total + Number(weight),
        0
      );
    },
    sumBoughtValueForCattleCategory() {
      const category = this.categories.find((c) => c.name === "BOVINOS");

      category.boughtQuantityPackings = this.sumBoughtPackingsWeight();
    },
    sumWeightByProductCategory() {
      this.categories = this.categories?.map((category) => {
        return {
          ...category,
          soldQuantity: this.sumProductsByProductCategoryId(
            category.uid,
            this.explodeProductsInOrders()
          ),
          boughtQuantityEntries: this.sumProductsByProductCategoryId(
            category.uid,
            this.entries || []
          ),
        };
      });
    },
    async fetchCategories() {
      const { categories } = await ProductCategoryService().index();

      if (categories) this.categories = categories;
    },
    async fetchEntries() {
      const { entries } = await ProductEntriesService().index({
        created_at_start: this.StartOfMonth,
        created_at_end: this.EndOfMonth,
      });

      if (entries) {
        this.entries = entries;
        this.sumWeightByProductCategory();
      }
    },
    async fetchOrders() {
      this.isLoading = true;

      const filters = {
        include_product_category: true,
        delivered_at_start: this.StartOfMonth,
        delivered_at_end: this.EndOfMonth,
        status: "finalized,completed,paid,delivered",
      };

      if (this.searchWithInvoiceMode !== "Indiferente")
        filters.has_invoice = this.shouldFetchWithInvoices();

      const { orders } = await OrderService().index(filters);

      if (orders) {
        this.orders = orders;
        this.sumWeightByProductCategory();
      } else this.orders = null;

      this.isLoading = false;
    },
  },
};
</script>
<style>
.el-table .success {
  background-color: #dcedc8 !important;
}
</style>
